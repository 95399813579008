import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import timezone from 'moment-timezone';
import map from 'lodash/map';

import { getCurrentOrganization } from '../../../Helpers/CurrentSession';
import { restRequest } from '../../../Helpers/RequestHelper';
import COUNTRY_STATES from '../../../Constants/CountryState';

import OrganizationFormEnhanced from './OrganizationFormEnhancer';
import Loader from './../../Loader/Loader';
// import { StateShortName } from '../../../Helpers/StateNameConvertion'

import './OrganizationForm.css';
import { checkError } from '../../../Helpers/AuthHelper';

class OrganizationForm extends Component {
  id = window.location.search.slice(1).split('=')[1];
  type = this.props.type;
  state = {
    organization: {
      currentLogo: null,
      organizationCurrencies: '',
      organizationName: '',
      organizationType: null,
      organizationTimezone: 'America/Los_Angeles',
      organizationInventoryStartDate: timezone.tz(
        new Date(),
        'America/Los_Angeles'
      ),
      organizationStreet: '',
      organizationProvince: '',
      organizationPostalCode: '',
      organizationCity: '',
      organizationCountry: null,
      phone: '',
      organizationWebsite: '',
      organizationBaseCurrency: '',
      primaryContactName: '',
      primaryContactEmail: '',
      organizationLogo: null,
      dateFormat: 'long6',
      dateSeparator: '/',
      fiscalYear: null,
      license_no: '',
    },
    disable_currency: false,
    loading: true,
    organizationTypes: [],
    allCurrencies: [],
    currencyEditable: true,
    organizationId: this.id ? this.id : getCurrentOrganization().id,
    countryList: [],
    countryStates: [],
    imagestate: '',
  };

  componentDidMount() {
    this.fetchData();
    // window.onbeforeunload = function () {
    //   return true;
    // };
  }

  // componentWillUnmount() {
  //   window.onbeforeunload = null;
  // }

  async fetchData() {
    this.setState({ loading: true });

    if (this.type !== 'Add') {
      await Promise.all([
        this.fetchOrganizationEditData(),
        this.fetchCountryList(),
      ]);
      this.populateCountryStates(this.state.organization.organizationCountry);
    } else {
      await Promise.all([
        this.fetchOrganizationCreateData(),
        this.fetchCountryList(),
      ]);
    }

    this.setState({
      loading: false,
    });
    // Setting the states list temprary,because we only have one country right now
    this.populateCountryStates(230);
  }

  fetchCountryList() {
    return restRequest('get', 'country')
      .then((countryRes) => {
        this.setState({
          countryList: countryRes,
        });
      })
      .catch((error) => {
        checkError(error);
      });
  }

  fetchOrganizationCreateData() {
    return restRequest('get', 'organizations/create', {})
      .then((res) => {
        this.setState({
          allCurrencies: res.currency_list,
          organizationTypes: res.organization_type_list,
          fiscalYearFromDB: res.fiscal_year_list,
        });
      })
      .catch((error) => {
        checkError(error);
      });
  }

  async fetchOrganizationEditData() {
    return restRequest(
      'get',
      `organizations/${this.state.organizationId}/edit`,
      {}
    )
      .then((res) => {
        let organization = res.organization;
        var org_base_currency = res.all_currencies.filter(
          (cur) => cur.currency_code === res.currency_list[0].currency_code
        );
        //console.log(org_base_currency)
        this.setState({
          organization: {
            organizationName: organization.name,
            // organizationLogo: !organization.logo ? {} : organization.logo,
            currentLogo: organization.logo,
            organizationType:
              organization.organization_type_id != null
                ? organization.organization_type_id
                : '1',
            organizationTimezone:
              organization.time_zone != null
                ? organization.time_zone
                : 'America/Los_Angeles',
            organizationInventoryStartDate:
              organization.inventory_start_date != null
                ? moment(organization.inventory_start_date)
                : moment(),
            fiscalYear: organization.fiscal_year_id,
            organizationStreet:
              organization.company_street != null
                ? organization.company_street
                : '',
            //organizationProvince: ((organization.company_province != null) ? StateShortName(organization.company_province) : ''),
            organizationProvince:
              organization.company_province != null
                ? organization.company_province
                : '',
            organizationPostalCode:
              organization.company_postal_code != null
                ? organization.company_postal_code
                : '',
            organizationCity:
              organization.company_city != null
                ? organization.company_city
                : '',
            organizationCountry: organization.country_id,
            phone: organization.phone != null ? organization.phone : '',
            organizationWebsite:
              organization.company_website != null
                ? organization.company_website
                : '',
            organizationBaseCurrency: org_base_currency[0].id,
            primaryContactName:
              organization.primary_contact_name != null
                ? organization.primary_contact_name
                : '',
            primaryContactEmail:
              organization.primary_contact_email != null
                ? organization.primary_contact_email
                : '',
            dateFormat:
              organization.date_format != null
                ? organization.date_format
                : 'medium1',
            dateSeparator:
              organization.date_separator != null
                ? organization.date_separator
                : '/',
            license_no: organization.license_no ? organization.license_no : '',
          },
          organizationTypes: res.organization_type_list,
          fiscalYearFromDB: res.fiscal_year_list,
          allCurrencies: res.all_currencies,
          disable_currency: res.disabled_currency,
        });
      })
      .catch((error) => {
        checkError(
          error,
          this.props.handleToast,
          'Failed to perform your request, please try again.'
        );
      });
  }

  populateCountryStates = (countryId) => {
    if (countryId) {
      const countryFromList = this.state.countryList.find(
        (country) => country.id === countryId
      );
      if (countryFromList) {
        const country = COUNTRY_STATES.find(
          (item) => item.country === countryFromList.country_name
        );
        if (country) {
          const states = country.states.map((state) => ({
            value: state,
            label: state,
          }));
          this.setState({ countryStates: states });
          return;
        }
      }
    }
    this.setState({ countryStates: [] });
  };

  submitRequestUrl() {
    if (this.type === 'Add') return 'organizations';
    return `organizations/${this.state.organizationId}`;
  }

  submitRequestType() {
    return 'post';
  }

  successMessage = () => {
    if (this.type === 'Add') {
      this.props.handleToast('Business added successfully.', 'success');
    } else {
      this.props.handleToast('Business has been updated', 'success');
    }
  };

  handleCurrencyOptions(currencyOptions) {
    const renderOptions = [];
    let option = {
      value: '',
      label: '',
    };
    map(currencyOptions, (currency) => {
      option = {
        value: currency.id,
        label: `${currency.currency_code} - ${currency.symbol} - ${currency.name}`,
      };
      renderOptions.push(option);
    });
    return renderOptions;
  }

  handleChangeLogo = (logo) => {
    this.setState({
      imagestate: logo,
    });
  };

  render() {
    const {
      type,
      forceUpdate,
      history,
      handleToast,
      onSubmit,
      handleCrossAlert,
      setPrompt,
    } = this.props;
    const {
      loading,
      organization,
      organizationTypes,
      fiscalYearFromDB,
      allCurrencies,
      currencyEditable,
      organizationId,
      countryList,
      countryStates,
      disable_currency,
      imagestate,
    } = this.state;
    if (loading) return <Loader />;
    return (
      <OrganizationFormEnhanced
        type={type}
        organization={organization}
        organizationTypes={organizationTypes}
        fiscalYearFromDB={fiscalYearFromDB}
        currencyOptions={this.handleCurrencyOptions(allCurrencies)}
        currencyEditable={currencyEditable}
        countryList={countryList}
        countryStates={countryStates}
        populateCountryStates={this.populateCountryStates}
        onSubmit={onSubmit}
        forceUpdate={forceUpdate}
        history={history}
        handleToast={handleToast}
        requestType={this.submitRequestType()}
        requestUrl={this.submitRequestUrl()}
        successMessage={this.successMessage}
        organizationId={organizationId}
        disable_currency={disable_currency}
        handleCrossAlert={handleCrossAlert}
        imagestate={imagestate}
        handleChangeLogo={this.handleChangeLogo}
        setPrompt={setPrompt}
      />
    );
  }
}

export default withRouter(OrganizationForm);
