import React from 'react';
import { MdClose } from '../../Common/Icons';
import { imagePath } from '../../Helpers/RequestHelper';
import styles from './ImagePreview.module.css';

const ImagePreview = ({ images, onDeleteImage }) => {
  return (
    <div className={styles.imagePreviewContainer}>
      {images.map((img, index) => {
        return (
          <div
            key={index}
            className={styles.imagePreview}
            style={{
              backgroundImage: !img.preview
                ? `url(${imagePath(img)})`
                : `url(${img.preview})`,
            }}
          >
            <button
              name={img.name}
              type="button"
              className="button--image-delete"
              onClick={() => onDeleteImage(img)}
            >
              <MdClose className={styles.closeIconTransition} />
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default ImagePreview;
