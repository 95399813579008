import React, { Component } from 'react';

import {
  // PrefixPrefIcon,
  // SettingsIcon,
  NewAccountIcon,
} from '../../Assets/Settings/SettingsIcon';
import TaxIcon from '../../Assets/General/TaxIcon';
import DeliveryIcon from '../../Assets/General/DeliveryMethodIcon';
import WarehouseIcon from '../../Assets/General/WarehouseIcon';
import CurrencyIcon from '../../Assets/General/CurrencyIcon';
import PreferencesIcon from '../../Assets/General/PreferenceIcon';
import Loader from '../../Components/Loader/Loader';
import { Header } from '../../Components/Layout/Layout';
import SettingsCard from '../../Components/Card/SettingsCard';
import SettingsPageIcon from '../../Assets/General/SettingPageIcon';
import AccessDenied from '../../Components/AccessDenied/AccessDenied';

import './Settings.css';
import {
  hasPermissionCustom,
  hasViewPermission,
} from '../../Helpers/CustomPermission';
import {checkError, setDeploymentTime} from '../../Helpers/AuthHelper';
import { restRequest } from '../../Helpers/RequestHelper';

export default class Settings extends Component {
  state = {
    loading: true,
    taxcount: 0,
    deliverycount: 0,
    accessDenied: false,
  };

  async fetchData() {
    await this.fetchFilteredData();
  }

  componentDidMount() {
    sessionStorage.removeItem('tab');
    document.title = 'Settings';
    this.fetchDeployment();
    this.fetchData().then(() => this.setState({ loading: false }));
  }

  async fetchDeployment() {
    await restRequest('get', `deploymentTime`)
      .then((res) => {
        setDeploymentTime(res);
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
  }

  fetchFilteredData() {
    this.setState({ loading: true, reportLoading: true });

    return restRequest('get', `accounts/system-settings`)
      .then((res) => {
        this.setState({
          taxcount: res.taxes,
          deliverycount: res.deliveryMethods,
          reportLoading: false,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error?.response?.data?.status !== 'access_denied') {
          this.setState({
            accessDenied: checkError(error, this.props.handleToast),
          });
        } else {
          this.setState({ accessDenied: true });
        }
      });
  }

  render() {
    let { taxcount, deliverycount, accessDenied, loading } = this.state;
    if (loading) return <Loader />;
    if (accessDenied) {
      return <AccessDenied type="section" />;
    }
    return (
      <div className="settings">
        <Header title="Settings - System" Icon={SettingsPageIcon}></Header>
        <div className="float-left w-70 seetings_card_parent">
          <div className="colum_3">
            <SettingsCard
              showCreate={hasPermissionCustom('tax', 'Create')}
              showView={hasViewPermission('tax')}
              icon={<TaxIcon />}
              link={taxcount > 0 ? '/taxes' : ''}
              title="Taxes"
              subtitle={
                taxcount > 0
                  ? 'All your tax details in one place'
                  : 'Create a tax to start managing it'
              }
            />
          </div>
          <div className="colum_3">
            <SettingsCard
              showCreate={hasPermissionCustom('deliverymethod', 'Create')}
              showView={hasViewPermission('deliverymethod')}
              icon={<DeliveryIcon />}
              link={deliverycount > 0 ? '/deliverymethods' : ''}
              title="Delivery Methods"
              subtitle={
                deliverycount > 0
                  ? 'Add your Delivery Methods'
                  : 'Create a Delivery Method to proceed'
              }
            />
          </div>
          <div className="colum_3">
            <SettingsCard
              showCreate={hasPermissionCustom('warehouse', 'Create')}
              showView={hasViewPermission('warehouse')}
              icon={<WarehouseIcon />}
              link="/warehouses"
              title="Warehouses"
              subtitle="Manage your warehouses"
            />
          </div>
          <div className="colum_3">
            <SettingsCard
              showCreate={hasPermissionCustom('account', 'Create')}
              showView={hasViewPermission('account')}
              icon={<NewAccountIcon />}
              link="/accounting/account"
              title="Inventory Account"
              subtitle="Create inventory account"
            />
          </div>
          <div className="colum_3">
            <SettingsCard
              showCreate={false}
              showView={hasViewPermission('currency')}
              icon={<CurrencyIcon />}
              link="/currencies"
              title="Currency Settings"
              subtitle="Add and adjust your currency settings"
            />
          </div>
          <div className="colum_3">
            <SettingsCard
              showCreate={false}
              showView={hasViewPermission('preference')}
              icon={<PreferencesIcon />}
              link="/preference"
              title="Preferences"
              subtitle="Customize settings according to your needs"
            />
          </div>
          <div className="colum_3">
            <SettingsCard
              showCreate={hasPermissionCustom('paymentmode', 'Create')}
              showView={hasViewPermission('paymentmode')}
              icon={<CurrencyIcon />}
              link="/paymentmethods"
              title="Payment Methods"
              subtitle="Add your Payment Methods"
            />
          </div>
        </div>
      </div>
    );
  }
}
