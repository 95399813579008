import React, { Component, Fragment } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';

import { restRequest } from '../../../Helpers/RequestHelper';
import {
  required,
  ValidateDate,
  ValidateMaxOnlyDigi50,
  ValidateMax255,
  ValidateMax10BillionPos,
  ValidateMax50,
} from '../../../Helpers/Validator';
import { getOrganizationDate } from '../../../Helpers/Date/OrganizationDate';

import { getObjFromLS, setObjInLS } from '../../../Helpers/LocalStorage';
import Form from '../../../Components/Form/Form';
import Field from '../../../Components/Form/Field/Field';
import Loader from '../../../Components/Loader/Loader';
import FormAction from '../../../Components/Form/FormAction';
import ErrorFocus from '../../../Components/Form/ErrorFocus';
import DeliveryMethodForm from '../../../Components/Form/DeliveryForm/DeliveryMethodForm';
import './ShipmentCreate.css';
import DeliveryIcon from '../../../Assets/General/DeliveryMethodIcon';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import { checkError } from '../../../Helpers/AuthHelper';
import { validateShipmentForm } from '../../../Helpers/FormValidator';
import { confirmationAlert } from '../../../Helpers';

class ShipmentCreate extends Component {
  saveAndSend = false;
  history = this.props.Componenthistory;
  state = {
    shipment: {
      shipmentOrderNo: '',
      shipmentDate: null,
      packageId: 0,
      carrier: '',
      trackingNo: '',
      notes: '',
      shippingCharge: null,
    },
    deliveryMethods: null,
    currency: {},
    loading: true,
    showCreateDeliveryMethodModal: false,
    showPrompt: false,
    alertFlag: false,
    pkg_date: null,
  };
  myRef = React.createRef();

  componentDidMount() {
    localStorage.setItem('alert', false);
    this.setState({
      packageId: this.props.packageId,
    });
    restRequest('get', `shipments/create?&package_id=${this.props.packageId}`)
      .then((res) => {
        this.setState({
          shipment: {
            ...this.state.shipment,
            shipmentOrderNo: res.shipmentNo,
            shipmentDate:
              res?.package?.package_date &&
              moment(res.package.package_date) < moment(getOrganizationDate())
                ? moment(getOrganizationDate()).format('YYYY-MM-DD 00:00:00')
                : res.package.package_date,
          },
          currency: res.base_currency,
          deliveryMethods: res.delivery_methods,
          pkg_date: res.package.package_date,
          loading: false,
        });
      })
      .catch((error) => checkError(error));
    window.onbeforeunload = function () {
      return true;
    };
  }
  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.alertFlag !== this.state.alertFlag) {
      const { checkout } = validateShipmentForm(
        this.myRef?.current?.state?.values,
        this.myRef?.current?.initialValues
      );
      if (!checkout) localStorage.setItem('alert', true);
      else localStorage.setItem('alert', false);
    }
  }

  handlealertFlag = () => {
    this.setState({
      alertFlag: !this.state.alertFlag,
    });
  };

  handleSubmit = (values) => {
    restRequest('post', 'shipments', {
      shipment_no: values.shipmentOrderNo,
      shipment_date: values.shipmentDate ? values.shipmentDate : null,
      package_id: values.packageId,
      carrier: values.carrier,
      tracking_no: values.trackingNo,
      shipping_charge: values.shippingCharge,
      note: values.notes,
    })
      .then((res) => {
        // this.props.onSubmit();
        this.props.handleToast('Shipment created successfully', 'success');
        let dataFromLS = getObjFromLS('module');
        if (dataFromLS === null) {
          let obj = {
            prevId: null,
            id: null,
            nextId: null,
            moduleName: '',
            urlPath: window.location.pathname,
            queryParam: window.location.search,
          };
          setObjInLS('module', obj);
          dataFromLS = getObjFromLS('module');
        }
        window.location.pathname.includes('packages') &&
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'packages',
            queryParam: `${
              dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            }packages=true`,
          });
        window.location.pathname.includes('salesorders') &&
          setObjInLS('module', {
            ...dataFromLS,
            moduleName: 'salesorders',
            urlPath: window.location.pathname,
            queryParam: `${
              dataFromLS.queryParam ? dataFromLS.queryParam + '&' : '?'
            }packages=true`,
          });
        if (this.saveAndSend) {
          this.history.push(`/shipment/email/${res.data.package_id}`);
        } else {
          this.history.push('/r');
          sessionStorage.setItem('fullscreen', false);
        }
      })
      .catch((error) => {
        this.props.handleToast(error, 'error');
        checkError(error);
      });
  };

  handleShippingChargesChange(shippingChargeValue, setFieldValue) {
    // Price_Quantity_Limit

    // price and quantity limit from 5 digits to 10 digits
    let shippingCharge = shippingChargeValue.floatValue;
    if (shippingCharge > 0) {
      let shippingChargeString = shippingCharge.toString();
      if (shippingChargeString.length > 10) {
        shippingChargeString = shippingChargeString.slice(0, 10);
      }
      shippingCharge = parseFloat(shippingChargeString);
    }
    setFieldValue('shippingCharge', shippingCharge ? shippingCharge : null);
  }

  handleCarrierChange(option, setFieldValue) {
    if (option.value === 'createNew') {
      this.setState({ showCreateDeliveryMethodModal: true });
    } else {
      setFieldValue('carrier', option.name);
    }
  }

  closeModal() {
    this.setState({
      showCreateDeliveryMethodModal: false,
    });
  }

  handlePrompt = (status = true) => {
    this.setState({ showPrompt: status });
  };

  deliveryMethodCreateModal = () => {
    return (
      <CustomModal
        className="add_new_deleivery"
        showModal={this.state.showCreateDeliveryMethodModal}
        title="Create New Delivery Method"
        renderActions={false}
        Icon={DeliveryIcon}
        fill="#000000"
        modaltitle="delivery_modal_title"
        size="large"
        onClose={() => this.setState({ showCreateDeliveryMethodModal: false })}
        classNames="salesorder-delivery-popup"
        showPrompt={this.state.showPrompt}
      >
        <DeliveryMethodForm
          inModal={true}
          closeModal={() =>
            this.setState({ showCreateDeliveryMethodModal: false })
          }
          onSubmitModal={(newitem) => {
            this.handleDeliveryMethodSubmit(newitem);
          }}
          type="add"
          handleToast={this.props.handleToast}
          modelSource={false}
          handlePrompt={this.handlePrompt}
        />
      </CustomModal>
    );
  };

  handleDeliveryMethodSubmit(item) {
    const { current } = this.myRef;
    const { setFieldValue = () => null } = current;
    this.state.deliveryMethods.push(item);
    setFieldValue('carrier', item.name);
    this.setState({
      ...this.state,
      deliveryMethods: this.state.deliveryMethods,
      showCreateDeliveryMethodModal: false,
    });
  }

  renderModals = () => {
    return <Fragment>{this.deliveryMethodCreateModal()}</Fragment>;
  };

  shipmentForm() {
    const { shipment, currency, packageId } = this.state;
    return (
      <>
        {this.renderModals()}
        <Formik
          ref={this.myRef}
          initialValues={{
            packageId: packageId,
            shipmentOrderNo: shipment.shipmentOrderNo,
            shipmentDate: shipment.shipmentDate,
            carrier: shipment.carrier,
            trackingNo: shipment.trackingNo,
            notes: shipment.notes,
            shippingCharge: shipment.shippingCharge,
          }}
          validationSchema={() =>
            Yup.object().shape({
              shipmentDate: ValidateDate,
              carrier: required(ValidateMax50),
              trackingNo: ValidateMaxOnlyDigi50,
              shippingCharge: ValidateMax10BillionPos,
              notes: ValidateMax255,
            })
          }
          onSubmit={(values) => {
            this.handleSubmit(values);
          }}
          render={({
            values,
            errors,
            isSubmitting,
            setSubmitting,
            touched,
            setFieldTouched,
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <Form className="shipment-create-form" onSubmit={handleSubmit}>
                <div className="form-fields-container d-flex flex-wrap shipment-form-main">
                  {this.props.forAllPackages ? (
                    <Field
                      className="flex-33"
                      type="dropdown"
                      name="Package No."
                      autoFocus={true}
                      id="packageId"
                      value={values.packageId}
                      options={this.props.packageList}
                      dropdownValue="id"
                      dropdownLabel="package_no"
                      handleChange={(option) => {
                        this.setState({
                          pkg_date: option.package_date,
                        });
                        setFieldValue(
                          'shipmentDate',
                          option.package_date &&
                            option.package_date >
                              moment(getOrganizationDate()).format(
                                'YYYY-MM-DD 00:00:00'
                              )
                            ? option.package_date
                            : moment(getOrganizationDate()).format(
                                'YYYY-MM-DD 00:00:00'
                              )
                        );
                        setFieldValue('packageId', option.id);
                        this.handlealertFlag();
                      }}
                      clearable={false}
                      onBlur={handleBlur}
                      required
                      error={touched.packageId && errors.packageId}
                    />
                  ) : (
                    <Field
                      name="Package No."
                      className="flex-33"
                      id="shipmentOrderNo"
                      disabled
                      value={this.props.packageNo}
                    />
                  )}

                  <Field
                    name="Shipment Order No."
                    className="flex-33"
                    autoFocus={true}
                    id="shipmentOrderNo"
                    value={values.shipmentOrderNo}
                    handleChange={(e) => {
                      handleChange(e);
                      this.handlealertFlag();
                    }}
                    onBlur={handleBlur}
                    required
                    error={touched.shipmentOrderNo && errors.shipmentOrderNo}
                  />

                  <Field
                    type="date"
                    name="Shipping Date"
                    className="flex-33"
                    id="shipmentDate"
                    value={
                      typeof values.shipmentDate == 'string'
                        ? moment(values.shipmentDate)
                        : values.shipmentDate
                    }
                    minDate={
                      typeof this.state.pkg_date == 'string'
                        ? moment(this.state.pkg_date)
                        : this.state.pkg_date
                    }
                    // value={values.shipmentDate}
                    // minDate={this.state.pkg_date}
                    handleChange={(date) => {
                      setFieldValue(
                        'shipmentDate',
                        date && date.format('YYYY-MM-DD 00:00:00')
                      );
                      this.handlealertFlag();
                      setSubmitting(false);
                    }}
                    onBlur={() => setFieldTouched('shipmentDate', true)}
                    required
                    error={touched.shipmentDate && errors.shipmentDate}
                  />

                  <Field
                    className="flex-33"
                    type="dropdown"
                    name="Carrier"
                    id="carrier"
                    value={values.carrier}
                    options={this.state.deliveryMethods}
                    dropdownValue="name"
                    dropdownLabel="name"
                    handleChange={(option) => {
                      this.handleCarrierChange(option, setFieldValue);
                      this.handlealertFlag();
                    }}
                    clearable={false}
                    onBlur={handleBlur}
                    required
                    error={touched && touched.carrier && errors.carrier}
                    addButton={true}
                  />

                  <Field
                    className="flex-33"
                    name="Tracking No."
                    id="trackingNo"
                    value={values.trackingNo}
                    handleChange={(e) => {
                      handleChange(e);
                      this.handlealertFlag();
                    }}
                    onBlur={handleBlur}
                    error={touched.trackingNo && errors.trackingNo}
                  />

                  <Field
                    type="number"
                    className="flex-33"
                    name="Shipping & Handling"
                    id="shippingCharge"
                    value={values.shippingCharge}
                    prefix={currency.symbol}
                    onValueChange={(value) => {
                      this.handleShippingChargesChange(value, setFieldValue);
                      this.handlealertFlag();
                    }}
                    onBlur={handleBlur}
                    error={touched.shippingCharge && errors.shippingCharge}
                  />

                  <Field
                    className="flex-66"
                    type="textarea"
                    name="Notes"
                    id="notes"
                    value={values.notes}
                    handleChange={(e) => {
                      handleChange(e);
                      this.handlealertFlag();
                    }}
                    onBlur={handleBlur}
                    error={touched.notes && errors.notes}
                  />
                </div>
                <FormAction
                  hasHr={true}
                  disabled={isSubmitting}
                  onCancel={() => {
                    let response;
                    const { checkout } = validateShipmentForm(
                      values,
                      this.myRef?.current?.initialValues
                    );
                    if (!checkout) response = confirmationAlert();
                    if (response || checkout) this.props.onClose();
                  }}
                  showSaveAndSendButton={true}
                  saveAndSendField={{
                    label: 'Save & Send',
                    disabled: { isSubmitting },
                    onClick: (event) => {
                      event.preventDefault();
                      this.saveAndSend = true;
                      handleSubmit();
                    },
                  }}
                />

                <ErrorFocus />
              </Form>
            );
          }}
        />
      </>
    );
  }

  render() {
    if (this.state.loading) return <Loader />;
    return this.shipmentForm();
  }
}

export default ShipmentCreate;
